<template>
  <div class="app-container h">
    <department-select v-model="query.deptId" @change="toQuery" style="width: 320px; margin-right: 20px;" />

    <div class="flex v">
      <div class="head-container">
        <el-button class="filter-item" size="mini" type="primary" icon="el-icon-plus" @click="add">新增</el-button>
        <el-button class="filter-item" size="mini" type="info" icon="el-icon-refresh-left" @click="toQuery">刷新</el-button>
      </div>
      <el-table v-loading="loading" row-key="id" :data="data" height="200px">
        <el-table-column prop="name" label="姓名" min-width="120" />
        <el-table-column label="性别" min-width="80">
          <template slot-scope="scope">{{scope.row.sex ? "男" : "女"}}</template>
        </el-table-column>
        <el-table-column prop="employeeNo" label="编码" min-width="150" />
        <el-table-column prop="email" label="邮箱" min-width="180" />
        <el-table-column label="状态" width="120">
          <template slot-scope="scope">
            <el-tag type="success" v-if="scope.row.status === 0">在职</el-tag>
            <el-tag type="info" v-else>离职</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="创建账户" width="100" align="center" fixed="right">
          <template slot-scope="scope">
            <span class="fc-g" v-if="scope.row.userId">已创建</span>
            <el-button size="mini" type="text" @click="newuser(scope.row)" v-else-if="scope.row.status === 0">立即创建</el-button>
          </template>
        </el-table-column>
        <el-table-column width="100" fixed="right">
          <div class="row-commands" slot-scope="scope">
            <el-button size="mini" type="text" @click="edit(scope.row)">编辑</el-button>
            <el-popover :ref="scope.row.id" placement="top" width="180">
              <p>确定删除本条数据吗？</p>
              <div style="text-align: right; margin: 0">
                <el-button size="mini" type="text" @click="$refs[scope.row.id].doClose()">取消</el-button>
                <el-button :loading="delLoading" type="primary" size="mini" @click="subDelete(scope.row.id)">确定</el-button>
              </div>
              <el-button slot="reference" class="danger" type="text" size="mini">删除</el-button>
            </el-popover>
          </div>
        </el-table-column>
      </el-table>

      <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />
    </div>
    <Form ref="form" :is-add="isAdd" />
    <Assignment ref="assignment" :is-add="isAdd" />
  </div>
</template>

<script>
import initData from "@/mixins/initData";
import { del, edit } from "@/api/contact";
import checkPermission from "@/utils/permission";
import Form from "./form";
import Assignment from "./assignment";
import DepartmentSelect from "../../assembly/departmentSelect";

export default {
  components: { Form, Assignment, DepartmentSelect },
  mixins: [initData],
  data() {
    return {
      isAdd: false,
      delLoading: false,
    };
  },
  created() {
    this.init();
  },
  methods: {
    checkPermission,
    beforeInit() {
      this.url = "api/employee";
      this.params = { sort: "name,desc", deptId: this.query.deptId };
      return true;
    },
    newuser(data) {
      this.isAdd = true;
      this.$refs.assignment &&
        this.$refs.assignment.restForm(JSON.parse(JSON.stringify(data)));
    },
    add() {
      this.isAdd = true;
      const _this = this.$refs.form;
      _this.restForm();
    },
    edit(data) {
      this.isAdd = false;
      this.$refs.form &&
        this.$refs.form.restForm(JSON.parse(JSON.stringify(data)));
    },
    handleNodeClick(data) {
      this.deptId = data.id;
      this.init();
    },
    //删除当前行数据功能
    subDelete(id) {
      this.delLoading = true;
      del(id)
        .then((res) => {
          this.delLoading = false;
          this.$refs[id].doClose();
          this.dleChangePage();
          this.init();
          this.$notify({
            title: "删除成功",
            type: "success",
            duration: 2500,
          });
        })
        .catch((err) => {
          this.delLoading = false;
          this.$refs[id].doClose();
          console.log(err.response.data.message);
        });
    },
  },
};
</script>